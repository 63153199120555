export default {
	//not available
	notavailable: 'ikkje tilgjengeleg',
	// tests is completed
	info_testwascompletedon: ' Testen vart gjennomført {0}',
	//report header strings
	newinfo2_belowpresentsinformation:
		'Nedanfor finn ein testrapporten for {0}{1}{2}',
	info_report_belowpresentsinformation:
		'Nedanfor finn ein testrapporten for {0}{1}{2}',
	months: 'månader',
	infor_scr_ageAtCommencement:
		' Alder ved testdato var {0} år og {1} månader.',
	translateToNorsk: 'Oversett til bokmål',
	//zscores labels
	zscorered: 'Z-skåre er meir enn 1 standardavvik {0} gjennomsnittet',
	zscoregreen: 'Z-skåre er meir enn 1 standardavvik {0} gjennomsnittet',
	zscoreblue: 'Z-skåre er innanfor gjennomsnittsområdet',

	//ordiktat
	orddiktatheading: `Kvart ord blir først presentert i ei setning for å sikre at riktig tyding av ordet er forstått. Deretter blir kandidaten bedd om å skrive det gjentatte stimulusordet. Det er inga tidsavgrensing på denne testen, og kvar setning og kvart stimulusord kan ein gjenta ein gong.`,
	orddiktatchildexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 13 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatadultexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 45 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatyouthexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatChildscrexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}.`,
	orddiktatvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//byttelydtest
	byttelydtestheading: `Denne testen måler fonembevisstheit og fonologisk prosesseringshastigheit, som er ein språkleg-kognitiv markør på dysleksi. Testen går ut på at kandidaten skal byte ut ein gitt lyd i ord med ein annan lyd, sånn at det blir eit nytt ord. Til dømes blir kandidaten bedd om å bytte ut lyden /b/ i «bil» med lyden /p/. På kvar oppgåve får kandidaten presentert ein illustrasjon av hovudordet (f.eks. bil), som så blir etterfølgd av fire illustrerte svaralternativ. Berre eitt av alternativa er riktig (her: pil). Kandidaten får 10 sekund til å løyse kvar oppgåve. Ei ny oppgåve kjem automatisk opp på skjermen dersom kandidaten ikkje har svart innan tidsfristen.`,
	byttelydtestexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 21). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	byttelydtestvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//ordkjedetesten
	ordkjedetestenheading: `Denne testen er ein stillelesingsprøve som måler hurtig ordattkjenning og ortografisk kunnskap. Her blir eleven bedd om å lage mellomrom mellom ord som er plassert saman i ei kjede. Kvar kjede består av fire tilfeldige ord. Testkandidaten får 4 minutt til å løyse så mange ordkjeder som mogleg.`,
	ordkjedetestenexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 40 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ordkjedetestenyouthexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 36 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ordkjedetestenvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//tulleordtesten
	tulleordtestenheading: `Denne testen er ein stillelesingsprøve som måler fonologisk bevisstheit og fonologisk prosesseringshastigheit. Dette reknar ein som ein språkleg-kognitiv markør på dysleksi. Testkandidaten skal finne det tulleordet som høyrest ut som eit ordentleg ord. På kvar oppgåve må testkandidaten velje blant fem alternativ, der berre eitt er riktig. Testkandidaten får to minutt til å løyse så mange oppgåver som mogleg.`,
	tulleordtestenexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 25 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	tulleordtestenvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//skrivehastighet
	skrivehastighetheading: `Denne testen måler effektivitet i skriving. Det er ei ferdigheit som ein nyttar når ein til dømes skal ta notat eller skrive svar på ei eksamensoppgåve. Kandidaten får sjå ei setning framfor seg heile tida, og skal skrive av denne så mange gonger og så nøyaktig som mogleg i løpet 2 minutt.`,
	skrivehastighetexplanation: `Skåren her er talet på riktig skrivne ord i snitt per minutt. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	skrivehastighetvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//leseprøven
	leseprøvenheading: `Denne testen er ein stillelesingsprøve som måler leseforståing ved at kandidaten må svare på spørsmål direkte frå teksten, sette saman informasjon frå ulike avsnitt og trekke konklusjonar. Kandidaten får 10 minutt til å lese teksten og svare på så mange fleirvalsspørsmål som mogleg. NB! Her er det viktig å registrere i kva grad testkandidaten har svart feil eller ikkje har svara på fleirvalsspørsmåla (på grunn av sein lesehastigheit).`,
	leseprøvenyouthheading: `Denne testen er ein stillelesingsprøve som måler leseforståing ved at kandidaten må svare på spørsmål direkte frå teksten, sette saman informasjon frå ulike avsnitt og trekke konklusjonar. Kandidaten får 12 minutt til å lese teksten og svare på så mange fleirvalsspørsmål som mogleg. NB! Her er det viktig å registrere i kva grad testkandidaten har svart feil eller ikkje har svara på fleirvalsspørsmåla (på grunn av sein lesehastigheit).`,
	leseprøvenchildheading: `Denne testen er ein stillelesingsprøve som måler leseforståing ved at kandidaten må svare på spørsmål direkte frå teksten, sette saman informasjon frå ulike avsnitt og trekke konklusjonar. Kandidaten får 10 minutt til å lese teksten og svare på så mange fleirvalsspørsmål som mogleg. NB! Her er det viktig å registrere i kva grad testkandidaten har svart feil eller ikkje har svara på fleirvalsspørsmåla (på grunn av sein lesehastigheit).`,
	leseprøvenexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 14 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	leseprøvenyouthexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 9 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	leseprøvenchildexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 8 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	leseprøvenvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//ordforståelse
	ordforståelseheading: `Testen måler ordforråd ved at kandidaten må finne eit ord som har lik tyding som det gitte stimulusordet. På kvar oppgåve må kandidaten velje blant fire alternativ. Denne testen gir eit mål på breidda og djupna av ordforrådet.`,
	ordforståelseexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 15 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ordforståelseyouthexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 20 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ordforståelsechildexplanation: `Det blir gitt eitt poeng for kvart riktig svar (maks 18 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ordforståelsevariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//screening test assessment
	localizedwriteuptextforNormalresults:
		'{0} oppnår resultat som er {1} {2} {3} gjennomsnittsområdet på {4} av deltestane. Dette indikerer at {5} har gode lese- og skriveferdigheiter.',
	localizedwriteuptextforOneOtherSubTest:
		'{0} har oppnådd eit resultat som ligg {1} {2} {3}. {4} {5} For vidare vurdering av resultata anbefaler vi at du les {6}, Kapittel 5. Retningslinjer for tolkning.',
	localizedwriteuptextforTwoOtherSubTest:
		'{0} har oppnådd eit resultat som ligg {1} {2} {3}. {4} Ettersom {5} oppnår svake resultat på {6}, {7} og {8} er det anbefalt å gjennomføre Dysmate oppfølgingstest. Dersom {9} på oppfølgingstesten oppnår svake resultat på Arbeidsminne og Hurtignemning, anbefaler vi å gjennomføre ein utvida kartlegging av språkforståing. For vidare vurdering av resultata anbefaler vi at du les {10}, Kapittel 5. Retningslinjer for tolkning.',
	localizedwriteuptextforThreeORMoreOtherSubTest:
		'{0} har oppnådd eit resultat som ligg {1} {2} {3}. {4} Ettersom {5} oppnår svake resultat på {6}, {7}, {8} {9} {10} {11} er det anbefalt å gjennomføre Dysmate oppfølgingstest. For vidare vurdering av resultata anbefaler vi at du les {12}, Kapittel 5. Retningslinjer for tolkning',
	localizedwriteuptextforIncompletedTest: `{0} har ikkje fullført heile screeningtesten, og det er derfor ikkje grunnlag for å kome med nokre anbefalingar om vidare oppfølging`,
	localizedwriteuptextforLowScoreOfOrdiktatWithOneOtherSubTest:
		'Rettskrivingsvanskar kan vere ein indikasjon på dysleksi. {0} sitt resultat på Orddiktat taler for at ein bør gjennomføre Dysmate oppfølgingstest.',
	localizedwriteuptextforLowScoreOfBetyledeWithOneOtherSubTest:
		'Resultatet på Byttelydtesten indikerer at ein bør gjennomføre ei nærmare kartlegging av {0} fonembevisstheit og fonologisk prosesseringshastigheit. Vi anbefaler derfor å gjennomføre Dysmate oppfølgingstest.',
	localizedwriteuptextforLowScoreInLeseprovenAndOrdforstaelse:
		'{0} har oppnådd eit resultat som ligg {1} {2} {3}. {4} Ettersom {5} oppnår svake resultat på deltestane Ordforståing og Leseprøve anbefaler vi derfor Dysmate oppfølgingstest. For vidare vurdering av resultata anbefaler vi også at du les {6}, Kapittel 5. Retningslinjer for tolkning.',

	//followup tests strings
	//fonemutelatelse
	fonemutelatelseheading: `Denne testen kartlegg fonembevisstheit. Kandidaten blir munnleg presentert for ord, og bedd om å seie det ordet som blir igjen når ein bestemt lyd blir tatt bort.`,
	fonemutelatelseexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	fonemutelatelsevariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//fonemisolasjon
	fonemisolasjonheading: `Denne testen kartlegg fonembevisstheit hos dei yngste kandidatane. På denne testen blir kandidaten presentert for fire bilde, og blir bedd om å peike på det bildet som enten byrjar med, eller sluttar på, ein bestemt lyd.`,
	fonemisolasjonexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 16 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	fonemisolasjonvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//arbeidsminne
	arbeidsminnenummberheading: `Denne testen kartlegg kandidaten sin evne til å lagre og omarbeide informasjon mens ein løyser ei oppgåve. Kandidaten blir først presentert for ein serie med tal, deretter ein serie med ord, som ho/han skal gjenta i omvend rekkefølge enn opphavleg presentert. Både talrekkene og ordrekkene aukar i lengde.`,
	arbeidsminneheading: `Denne testen kartlegg kandidaten sin evne til å lagre og omarbeide informasjon mens ein løyser ei oppgåve. Kandidaten blir først presentert for ein serie med tal, deretter ein serie med ord, som ho/han skal gjenta i omvend rekkefølge enn opphavleg presentert. Både talrekkene og ordrekkene aukar i lengde.`,
	arbeidsminneexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 12 poeng for talrekkene og maks 12 poeng for ordrekkene, til saman maks 24 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	arbeidsminnevariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//hurtigbenevning
	hurtigbenevningheading: `Denne testen kartlegg kor raskt kandidaten greier å nemne kjende objekt, og å hente fram namn på objekt og farger frå det språklege langtidsminnet.`,
	hurtigbenevningexplanation: `Det blir gitt eitt poeng for kvar riktige kombinasjon av farge og objekt kandidaten seier. Testen går også på tid. Skåren her er riktige fargeobjekt-kombinasjonar dividert med den totale tida det tar å nemne alle 50 fargeobjekt-kombinasjonane. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	hurtigbenevningvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,
	hurtigbenevningBHexplanation: `Det blir gitt eitt poeng for kvar riktige kombinasjon av farge og objekt kandidaten seier. Testen går også på tid. Skåren her er riktige fargeobjekt-kombinasjonar dividert med den totale tida det tar å nemne alle 25 fargeobjekt-kombinasjonane. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,

	//ettminuttstest
	ettminuttstestheading: `Dette er ein høgtlesingsprøve som kartlegg avkodingsferdigheiter. Her blir kandidaten presentert for ei liste med ord og bedd om å lese så fort og nøyaktig som mogleg innan ei tidsramme på 60 sekund Resultatet her gir eit mål på talet på riktig lesne ord per minutt.`,
	ettminuttstestexplanation: `Det blir gitt eitt poeng for kvart riktige lesne ord. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	ettminuttstestvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//spoonerism
	spoonerismheading: `Denne testen kartlegg fonembevisstheit og fonologisk prosesseringshastigheit. Kandidaten blir munnleg presentert for 20 ordpar, og bedd om å byte om første lyden i dei to orda så raskt og nøyaktig som mogleg.`,
	spoonerismexplanation: `Det blir gitt eitt poeng for kvart riktige ord kandidaten greier å seie med den nye førstelyden (maks 40 poeng). Testen går også på tid. Skåren her er tal på riktige ord som har bytt første lyd, dividert med den tida det tar å gjennomføre oppgåvene. Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	spoonerismvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,

	//bokstavkunnskap
	bokstavkunnskapheading: `Denne testen kartlegg bokstavkunnskap hos dei yngste kandidatane. Her blir kandidaten presentert for 15 av bokstavane i det norske alfabetet i tilfeldig rekkefølge. Kandidaten blir så bedd om å seie både namnet og lyden til bokstavane.`,
	bokstavkunnskapexplanation: `Det blir gitt eitt poeng for kvart riktige svar (maks 15 poeng for lyd og maks 15 poeng for namn, til saman maks 30 poeng). Gjennomsnittsskåren for {0} er {1} og standardavviket er {2}`,
	bokstavkunnskapvariabletext: `{0} oppnådde på denne testen ein skåre på {1}. Denne skåren plasserer seg {2} standardavvikseiningar frå gjennomsnittet for trinnet. {3} oppnår dermed eit resultat som plasserer seg`,
	bokstavkunnskapvariabletextBH: `{0} oppnådde på denne testen ein skåre på {1}. Gjennomsnitt og standardavvik for dette trinnet er ikkje tilgjengeleg.`,

	//followup test assessment
	asessmenttextok_1:
		'{0} har oppnådd resultat som ligg i gjennomsnittsområdet eller bedre for {1} av testene, og ser derfor ut til å ha gode lese- og skriveferdigheiter',
	asessmenttextnotok_1:
		'{0} har oppnådd resultat som ligg under gjennomsnittsområdet på {1} av dei {2} testane, og vi anbefaler derfor ei vidare kartlegging med tanke på dysleksi og/eller leseforståingsvansker',
	assesmenttextok_youthfollowup:
		'{0} har ingen utslag på dei typiske kognitive markørane på dysleksi.',
	assesmentok_recommendation_youthfollowup:
		'Dersom {0} likevel har oppnådd skårar under gjennomsnittsområdet på Leseprøven og/eller Ordforståing, eller eventuelt på nokre av dei andre av testane i DYSMATE screeningtest, anbefaler vi ei vidare kartlegging med tanke på mogleg leseforståingssvikt og/eller mogleg underliggande utviklingsmessig språkforstyrring (DLD).',
	workingmemorylowassesment:
		'{0} har utslag på Arbeidsminne, og oppnår ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin på denne testen. Arbeidsminne er i seg sjølv ikkje ein kognitiv markør på dysleksi, men er assosiert med både dysleksi og svekt leseforståing. Redusert arbeidsminne kan blant anna påverke kor mykje informasjon ein greier å få med seg frå den lesne teksten. Ein anbefaler derfor å undersøke i kor stor grad arbeidsminnet {2} påverkar tileigninga av nytt fagstoff, og om det er behov for skolefagleg tilrettelegging rundt dette.',
	rapidnominationlowassesment:
		'{0} har utslag på Hurtignemning, og oppnår ein skår som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin på denne testen. Hurtignemningstesten måler kor raskt og nøyaktig ein greier å hente fram ord frå språkminnet. Svake ferdigheiter på dette området er ein kognitiv markør på dysleksi.',
	oneminutelowassesment:
		'{0} har utslag på 1-minuttstesten, og oppnår ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for sitt trinn eller sin alder. 1-minuttstesten gir eit reint mål på talet på riktig lesne ord per minutt, og gir eit godt bilde av avkodingsferdigheiter. Svake avkodingsferdigheiter er ein markør på dysleksi.',
	oneminutelowassesment2:
		'NB! Ein anbefaler å vurdere resultata på Eittminuttstesten i samanheng med resultata på Diktatprøven og Ordkjedetesten på DYSMATE screeningtest.',
	spoonerismlowassesment:
		'{0} har utslag på Spoonerism, og oppnår på denne testen ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin. Spoonerism-testen måler fonembevisstheit og fonologisk prosesseringshastigheit. Svake ferdigheiter på desse områda er ein kognitiv markør på dysleksi.',
	fonemutelatelselowassesment:
		'{0} har utslag på Fonemutelating, og oppnår på denne testen ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin. Testen måler fonembevisstheit og fonologisk prosesseringshastigheit. Svake ferdigheiter på desse områda er ein kognitiv markør på dysleksi.',
	assessmentokBHtest:
		'{0} har oppnådd resultat som ligg i gjennomsnittsområdet eller betre for testane, og ser ut til å plassere seg på nivået som er typisk for sin alder på områda som er viktig for å utvikle gode lese- og skriveferdigheiter.',
	assessmentBHonlyLK:
		'{0} har lågare enn forventa bokstavkunnskap for trinnet. Dette kan ha samanheng med framgangsmåten for bokstavinnlæringa på skolen. Vi anbefaler likevel at ein følger nøye med utviklinga i elevens bokstav-lyd-innlæring.',
	assessmentBHRI:
		'{0} har utslag på Hurtignemning, og oppnår ein skår som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin på denne testen. Hurtignemningstesten måler kor raskt og nøyaktig ein greier å hente fram ord frå språkminnet. Svake ferdigheiter på dette området er ein kognitiv markør på dysleksi.',
	assessmentBHWM:
		'{0} har utslag på Arbeidsminne, og oppnår ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin på denne testen. Arbeidsminne er i seg sjølv ikkje ein kognitiv markør på dysleksi, men er assosiert med både dysleksi og svekt leseforståing. Redusert arbeidsminne kan blant anna påverke kor mykje informasjon ein greier å få med seg får den lesne teksten. Ein anbefaler derfor å undersøke i kor stor grad arbeidsminnet {2} påverkar tileigninga av nytt fagstoff, og om det er behov for skolefagleg tilrettelegging  dette.',
	assessmentBHFI:
		'{0} har utslag på Fonemisolasjon, og oppnår på denne testen ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin. Fonemisolasjonstesten måler fonembevisstheit og fonologisk prosesseringshastigheit. Svake ferdigheiter på desse områda er ein kognitiv markør på dysleksi.',
	assessmentBHFU:
		'{0} har utslag på Fonemutelating, og oppnår på denne testen ein skåre som ligg {1} standardavvikseiningar under gjennomsnittet for trinnet sitt eller alderen sin. Testen måler fonembevisstheit og fonologisk prosesseringshastigheit. Svake ferdigheiter på desse områda er ein kognitiv markør på dysleksi.',
};
