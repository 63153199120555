export function getPronoun(gender, language) {
	if (gender === 'm' && (language === 'no' || language === 'nn')) {
		return 'Han';
	} else if (gender === 'f' && language === 'no') {
		return 'Hun';
	} else if (gender === 'f' && language === 'nn') {
		return 'Ho';
	}
	return 'Hen';
}
