import LocalizedStrings from 'react-localization';
import no from 'languages/no';
import nn from 'languages/nn';

const strings = new LocalizedStrings({
	no: no,
	nn: nn,
});

export default strings;
