/* eslint-disable complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React from 'react';
import classNames from 'classnames';
import TestUserInfo from './TestUserInfo';
import Subtest from './Subtest';
import SubtestReport from './SubtestReport';
import SubtestAnswers from './SubtestAnswers';
import strings from 'utils/strings';
import getQuestionChoices from '../Utils/getQuestionChoices';
import moment from 'moment';
import {connect} from 'react-redux';
import '../../TestView/styles.scss';

import getSingleTestResult from 'api/getSingleTestResult';
import updateLogEvent from 'api/updateLogEvent';

import {withRouter} from 'react-router-dom';

import HorizontalBarChart from 'components/HorizontalBarChart';

import childWordchainAnswers from './child-wordchain-answers.json';
import childWordchainAnswersNn from './child-wordchain-answers-nn.json';
import test5Questions from './test5.json';
import test6Questions from './test6.json';
import test5youthQuestions from './test5youth.json';
import test5youthQuestionsNn from './test5youthNn.json';
import test6youthQuestions from './test6youth.json';
import test6youthQuestionsNn from './test6youthNn.json';

import test5childQuestions from './test5child.json';
import test5childQuestionsNn from './test5childNn.json';
import test6childQuestions from './test6child.json';
import test6childQuestionsNn from './test6childNn.json';
import test6childUpperQuestions from './test6childUpper.json';
import test6childUpperQuestionsNn from './test6childUpperNn.json';
import {timeSinceWCaltered} from '../../../constants';
import HandBook from './HandBook';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint, faLanguage} from '@fortawesome/free-solid-svg-icons';
import Assessment from './assessment';
import AddNotesModal from 'components/AddNotesModel';
import AlertBox from 'components/CreateNotification/alert';
class SingleTest extends React.Component {
	constructor() {
		super();
		this.state = {
			showTestUserInfo: false,
			testResult: null,
			testType: null,
			testId: null,
			testKey: null,
			showResultsTab: false,
			showReportTab: true,
			showInformationTab: false,
			showHandBookTab: false,
			showResultsActive: 'tablink1',
			showReportActive: 'tablink2-active',
			showInformationActive: 'tablink3',
			showHandBookActive: 'tablink4',
			showAddNotesModal: false,
			reportNotes: '',
			showAlert: false,
			birthDate: null,
			testReportLang: 'no',
			buttonTabs: [
				{
					id: 2,
					value: 'report',
					buttonClass: 'tablink2-active',
					text: 'Rapport',
				},
				{
					id: 'defaultOpen',
					value: 'result',
					buttonClass: 'tablink1',
					text: 'Resultater',
				},
				{
					id: 3,
					value: 'info',
					buttonClass: 'tablink3',
					text: strings.userinformation,
				},
				{
					id: 4,
					value: 'book',
					buttonClass: 'tablink4',
					text: 'Håndbok',
				},
			],
		};
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		window.scrollTo(0, 0);
		getSingleTestResult(id).then((testResult) => {
			const testType = testResult.type;
			const testUser = testResult.testUser;
			const birthDate = testUser.hasOwnProperty('birthDate')
				? ` Født: ${moment(testUser.birthDate).format('DD.MM.YYYY')}`
				: '';

			testResult.subtests.map((subtest) => {
				let questions = false;
				switch (testType) {
					case 'adult-screening':
						if (subtest.id === 5) {
							questions = test5Questions;
						} else if (subtest.id === 6) {
							questions = test6Questions;
						}
						break;

					case 'youth-screening':
						if (subtest.id === 5 && subtest.language === 'no') {
							questions = test5youthQuestions;
						} else if (
							subtest.id === 5 &&
							subtest.language === 'nn'
						) {
							questions = test5youthQuestionsNn;
						} else if (
							subtest.id === 6 &&
							subtest.language === 'no'
						) {
							questions = test6youthQuestions;
						} else if (
							subtest.id === 6 &&
							subtest.language === 'nn'
						) {
							questions = test6youthQuestionsNn;
						}
						break;
					case 'child-screening':
						const processedTestResult =
							this.reOrderChildScreening(testResult);

						const {testUser} = processedTestResult;
						const childGrade = testUser.education.youthGrade;

						if (subtest.id === 5 && subtest.language === 'no') {
							questions =
								test5childQuestions[childGrade.toString()];
						} else if (
							subtest.id === 5 &&
							subtest.language === 'nn'
						) {
							questions =
								test5childQuestionsNn[childGrade.toString()];
						} else if (
							subtest.id === 6 &&
							subtest.language === 'no'
						) {
							if (childGrade < 5) {
								questions = test6childQuestions;
							} else {
								questions = test6childUpperQuestions;
							}
						} else if (
							subtest.id === 6 &&
							subtest.language === 'nn'
						) {
							if (childGrade < 5) {
								questions = test6childQuestionsNn;
							} else {
								questions = test6childUpperQuestionsNn;
							}
						}
						break;
				}

				if (subtest.id === 4) {
					subtest.formattedAnswers = this.formatTypingSpeedAnswers(
						subtest.answers
					);
				} else if (subtest.id === 1 && testType === 'child-screening') {
					const {testUser} = testResult;
					const childGrade = testUser.education.youthGrade;

					subtest.formattedAnswers =
						this.getAnswersWithCorrectAnswerforWD(
							subtest.answers,
							subtest.answersCorrect,
							questions,
							childGrade
						);
				} else if (subtest.id === 2 && testType === 'child-screening') {
					subtest.formattedAnswers =
						this.getAnswersWithCorrectAnswerforWC(
							subtest.createdAt,
							subtest.answers,
							subtest.answersCorrect,
							childWordchainAnswers,
							childWordchainAnswersNn,
							questions,
							subtest.language
						);
				} else {
					subtest.formattedAnswers = this.getAnswersWithCorrectAnswer(
						subtest.answers,
						subtest.answersCorrect,
						questions
					);
				}

				return subtest;
			});
			this.setState({
				testResult,
				testId: testResult.id,
				testKey: testResult.key,
				testType: testType,
				testUser,
				birthDate,
			});
		});
	}
	reOrderChildScreening(testResult) {
		let allSubtests = [];

		for (let i = 0; i < testResult.subtests.length; i++) {
			switch (testResult.subtests[i].id) {
				case 3:
					allSubtests[0] = testResult.subtests[i];
					break;
				case 2:
					allSubtests[1] = testResult.subtests[i];
					break;
				case 4:
					allSubtests[2] = testResult.subtests[i];
					break;
				case 6:
					allSubtests[3] = testResult.subtests[i];
					break;
				case 5:
					allSubtests[4] = testResult.subtests[i];
					break;
				case 1:
					allSubtests[5] = testResult.subtests[i];
					break;
			}
		}
		if (allSubtests.length) {
			allSubtests = allSubtests.filter(function (element) {
				return element !== undefined;
			});

			testResult.subtests = allSubtests;
		}
		return testResult;
	}

	updatePrintLog(action) {
		updateLogEvent('print', this.state.testId, '', action);
	}

	formatTypingSpeedAnswers(answers) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			const answerArray = answers[i].split(' ');
			output.push(
				answerArray.map((answer, index) => {
					return {
						id: index,
						value: answer,
					};
				})
			);
		}
		return output;
	}

	getAnswersWithCorrectAnswer(answers, answersCorrect, questions) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			let correctAnswerAltValues = null;
			//Saves us some looping if arrays are arranged as expected
			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value.toLowerCase().trim();
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue = currentCorrectAnswer.value
						.toLowerCase()
						.trim();

					if (
						answersCorrect[j] &&
						answersCorrect[j].hasOwnProperty('altValues')
					) {
						correctAnswerAltValues = answersCorrect[i].altValues;
					}
					const isCorrectAnswer =
						currentAnswerValue === currentCorrectAnswer.value ||
						currentAnswerValue === correctAnswerAltValues;

					const hasAltValues =
						Array.isArray(currentCorrectAnswer.altValues) ||
						correctAnswerAltValues;
					const isCorrectAltValue =
						hasAltValues &&
						currentCorrectAnswer.altValues.indexOf(
							currentAnswerValue
						) !== -1;

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer || isCorrectAltValue,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	getAnswersWithCorrectAnswerforWD(
		answers,
		answersCorrect,
		questions,
		childGrade
	) {
		const output = [];
		for (let i = 0; i < answers.length; i++) {
			let jInit = 0;
			//Saves us some looping if arrays are arranged as expected

			if (
				answers[i] &&
				answersCorrect[i] &&
				answers[i].id === answersCorrect[i].id
			) {
				jInit = i;
			}
			if (typeof answers[i].id === 'undefined') {
				break;
			}
			var punctuation = /[\.,?!]/g;
			const currentAnswer = answers[i];
			const currentAnswerValue = currentAnswer.value
				.toLowerCase()
				.trim()
				.replace(punctuation, '');
			for (let j = jInit; j < answersCorrect.length; j++) {
				const currentCorrectAnswer = answersCorrect[j];
				if (currentAnswer.id === currentCorrectAnswer.id) {
					const correctAnswerValue =
						childGrade < 5
							? currentCorrectAnswer.value.toLowerCase().trim()
							: currentCorrectAnswer.upperGradeValue
									.toLowerCase()
									.trim();

					const isCorrectAnswer =
						childGrade < 5
							? currentAnswerValue === currentCorrectAnswer.value
							: currentAnswerValue ===
							  currentCorrectAnswer.upperGradeValue;
					const hasAltValues = Array.isArray(
						currentCorrectAnswer.altValues
					);

					const answer = {
						id: currentAnswer.id,
						value: currentAnswerValue,
						isCorrect: isCorrectAnswer,
						correctValues: [correctAnswerValue],
					};

					if (hasAltValues) {
						answer.correctValues = [correctAnswerValue].concat(
							currentCorrectAnswer.altValues
						);
					}

					if (questions) {
						answer.question = questions[currentAnswer.id - 1]
							? questions[currentAnswer.id - 1].question
							: '';
						answer.choices = getQuestionChoices(
							questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1]
								: ''
						);
					}

					output.push(answer);
					break;
				}
			}
		}
		return output;
	}

	getAnswersWithCorrectAnswerforWC(
		createdAt,
		answers,
		answerCorrectBackEnd,
		answersCorrect,
		answerCorrectNn,
		questions,
		subtestLanauge
	) {
		const createdMoment = moment(createdAt);
		const correctAnswerFile =
			subtestLanauge === 'no' ? answersCorrect : answerCorrectNn;
		const output = [];
		if (createdMoment.isBefore(timeSinceWCaltered)) {
			for (let i = 0; i < answers.length; i++) {
				let jInit = 0;
				//Saves us some looping if arrays are arranged as expected
				if (
					answers[i] &&
					correctAnswerFile[i] &&
					answers[i].id === correctAnswerFile[i].id
				) {
					jInit = i;
				}
				if (typeof answers[i].id === 'undefined') {
					break;
				}
				const currentAnswer = answers[i];
				const currentAnswerValue = currentAnswer.value
					.toLowerCase()
					.trim();
				for (let j = jInit; j < correctAnswerFile.length; j++) {
					const currentCorrectAnswer = correctAnswerFile[j];
					if (currentAnswer.id === currentCorrectAnswer.id) {
						const correctAnswerValue = currentCorrectAnswer.value
							.toLowerCase()
							.trim();
						const isCorrectAnswer =
							currentAnswerValue === currentCorrectAnswer.value;

						const hasAltValues = Array.isArray(
							currentCorrectAnswer.altValues
						);
						const isCorrectAltValue =
							hasAltValues &&
							currentCorrectAnswer.altValues.indexOf(
								currentAnswerValue
							) !== -1;

						const answer = {
							id: currentAnswer.id,
							value: currentAnswerValue,
							isCorrect: isCorrectAnswer || isCorrectAltValue,
							correctValues: [correctAnswerValue],
						};

						if (hasAltValues) {
							answer.correctValues = [correctAnswerValue].concat(
								currentCorrectAnswer.altValues
							);
						}

						if (questions) {
							answer.question = questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1].question
								: '';
							answer.choices = this.getQuestionChoices(
								questions[currentAnswer.id - 1]
									? questions[currentAnswer.id - 1]
									: ''
							);
						}

						output.push(answer);
						break;
					}
				}
			}
		} else {
			for (let i = 0; i < answers.length; i++) {
				let jInit = 0;
				//Saves us some looping if arrays are arranged as expected
				if (
					answers[i] &&
					answerCorrectBackEnd[i] &&
					answers[i].id === answerCorrectBackEnd[i].id
				) {
					jInit = i;
				}
				if (typeof answers[i].id === 'undefined') {
					break;
				}
				const currentAnswer = answers[i];
				const currentAnswerValue = currentAnswer.value
					.toLowerCase()
					.trim();
				for (let j = jInit; j < answerCorrectBackEnd.length; j++) {
					const currentCorrectAnswer = answerCorrectBackEnd[j];
					if (currentAnswer.id === currentCorrectAnswer.id) {
						const correctAnswerValue = currentCorrectAnswer.value
							.toLowerCase()
							.trim();
						const isCorrectAnswer =
							currentAnswerValue === currentCorrectAnswer.value;

						const hasAltValues = Array.isArray(
							currentCorrectAnswer.altValues
						);
						const isCorrectAltValue =
							hasAltValues &&
							currentCorrectAnswer.altValues.indexOf(
								currentAnswerValue
							) !== -1;

						const answer = {
							id: currentAnswer.id,
							value: currentAnswerValue,
							isCorrect: isCorrectAnswer || isCorrectAltValue,
							correctValues: [correctAnswerValue],
						};

						if (hasAltValues) {
							answer.correctValues = [correctAnswerValue].concat(
								currentCorrectAnswer.altValues
							);
						}

						if (questions) {
							answer.question = questions[currentAnswer.id - 1]
								? questions[currentAnswer.id - 1].question
								: '';
							answer.choices = this.getQuestionChoices(
								questions[currentAnswer.id - 1]
									? questions[currentAnswer.id - 1]
									: ''
							);
						}

						output.push(answer);
						break;
					}
				}
			}
		}
		return output;
	}

	returnTestUserGrade(notAvailable) {
		let testUserGrade = null;
		const {testUser} = this.state;
		if (
			['child-screening', 'youth-screening'].includes(this.state.testType)
		) {
			testUserGrade = (
				<>
					<br />
					{`${testUser.education.youthGrade}.${strings.grade}`}
					<br />
				</>
			);
		} else if (
			testUser.education.hasOwnProperty('highestCompletedEducation')
		) {
			const highestCompletedEducation = testUser.education
				.highestCompletedEducation
				? testUser.education.highestCompletedEducation
				: notAvailable;

			testUserGrade = (
				<>
					<br />
					{`${strings.education}: ${highestCompletedEducation}`}
					<br />
				</>
			);
		}
		return testUserGrade;
	}

	setRelevantTab(key, index) {
		const {buttonTabs} = this.state;
		for (let i = 0; i < buttonTabs.length; i++) {
			if (
				i === index &&
				!buttonTabs[index].buttonClass.includes('active')
			) {
				const counter = index + 1;
				buttonTabs[index].buttonClass = `tablink${counter}-active`;
			} else {
				const counter = i + 1;
				buttonTabs[i].buttonClass = `tablink${counter}`;
			}
		}
		switch (key) {
			case 'result':
				this.setState({
					showResultsTab: true,
					showReportTab: false,
					showInformationTab: false,
					showTestUserInfo: false,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'report':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: true,
					showTestUserInfo: false,
					showHandBookTab: false,

					showHandBookActive: 'tablink4',
					buttonTabs,
				});
				break;
			case 'info':
				this.setState({
					showResultsTab: false,
					showReportTab: true,
					showInformationTab: false,
					showTestUserInfo: true,
					showHandBookTab: false,

					buttonTabs,
				});
				break;
			case 'book':
				this.setState({
					showResultsTab: false,
					showReportTab: false,
					showInformationTab: false,
					showHandBookTab: true,
					showTestUserInfo: false,
					buttonTabs,
				});
				break;
		}
	}
	toggleAddNotesModal = () => {
		this.setState({showAddNotesModal: !this.state.showAddNotesModal});
	};
	onClickTranslateReport = () => {
		const {testReportLang} = this.state;
		const {
			testResult: {
				subtests: [{language}],
			},
		} = this.state;
		let translateTo = 'nn';
		if (testReportLang === 'nn') {
			translateTo = 'no';
		}

		this.setState({testReportLang: translateTo});
	};
	render() {
		const {
			showTestUserInfo,
			testResult,
			birthDate,
			reportNotes,
			testReportLang,
		} = this.state;
		if (!testResult) {
			return null;
		}

		const {testUser} = testResult;
		const {
			currentUser: {
				firstName: adminFirstName = '',
				lastName: adminLastName = '',
			} = {},
		} = this.props;
		const {
			testResult: {
				subtests: [{language}],
			},
		} = this.state;
		const notAvailable =
			testReportLang === 'nn'
				? strings._props.nn.notavailable
				: strings.notavailable;
		const adminCompleteName = `${adminFirstName} ${adminLastName}`;
		const ownerFullName = testResult.adminFullName
			? testResult.adminFullName
			: language === 'nn'
			? '<Ikkje tilgjengeleg>'
			: '<Ikke tilgjengelig>';
		const isCommon = adminCompleteName === ownerFullName;
		const completeName = testUser.firstName
			? `${testUser.firstName} ${testUser.lastName}`
			: strings.hiddenname;
		const firstName = testUser?.firstName || '<Skjult navn>';

		const infoReportBelowPresentsInformation =
			testReportLang === 'nn'
				? strings._props.nn.info_report_belowpresentsinformation
				: strings.info_report_belowpresentsinformation;
		const infoTestWasCompletedOn =
			testReportLang === 'nn'
				? strings._props.nn.info_testwascompletedon
				: strings.info_testwascompletedon;
		const inforScrAgeAtCommencement =
			testReportLang === 'nn'
				? strings._props.nn.infor_scr_ageAtCommencement
				: strings.infor_scr_ageAtCommencement;
		const zscorered =
			testReportLang === 'nn'
				? strings._props.nn.zscorered
				: strings.zscorered;
		const zscoregreen =
			testReportLang === 'nn'
				? strings._props.nn.zscoregreen
				: strings.zscoregreen;
		const zscoreblue =
			testReportLang === 'nn'
				? strings._props.nn.zscoreblue
				: strings.zscoreblue;
		return (
			<div className="lit-single-test">
				<div>
					<div
						className={
							this.state.testType === 'youth-screening'
								? 'lit-single-youthtest-title-desc'
								: 'lit-single-adulttest-title-desc'
						}
					>
						<h1 className="lit-page-title">
							{this.state.showHandBookTab
								? strings.formatString(
										strings.handbooktitle,
										<>
											<b>HÅNDBOK</b>
											<br />
											<br />
										</>
								  )
								: this.state.showResultsTab
								? strings.formatString(
										strings.dynamicheaderfortestresult_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<b>
												<br />
												<br />
												{
													'Dysmate - C (Screeningtest for barn)'
												}
											</b>
										)
								  )
								: this.state.showTestUserInfo
								? strings.formatString(
										strings.dynamicheaderfortestcandidate_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<>
												<b>
													<br />
													<br />
													{
														'Dysmate - C (Screeningtest for barn)'
													}
												</b>
											</>
										)
								  )
								: this.state.showReportTab
								? strings.formatString(
										strings.dynamicheaderfortestreport_adult,
										this.state.testType ===
											'youth-screening' ? (
											<>
												<br />
												<br />
												<b>{strings.youthscreening}</b>
											</>
										) : this.state.testType ===
										  'adult-screening' ? (
											<>
												{' '}
												<br />
												<br />
												<b>{strings.adultscreening}</b>
											</>
										) : (
											<>
												<br /> <br />
												<b>
													{
														'Dysmate - C (Screeningtest for barn)'
													}
												</b>
											</>
										)
								  )
								: ''}
						</h1>
						<div>
							{!this.state.showHandBookTab &&
								this.state.showReportTab &&
								!this.state.showTestUserInfo && (
									<>
										<br />

										<span className="lit-printed-by">
											{'Administrator: '}
										</span>
										<b style={{marginLeft: '4px'}}>
											{ownerFullName}
										</b>
										{!isCommon && (
											<>
												<span className="lit-printed-by">
													{'Lagret av: '}
												</span>
												<b style={{marginLeft: '4px'}}>
													{adminCompleteName}
												</b>
											</>
										)}
									</>
								)}
						</div>
						{!this.state.showHandBookTab && (
							<p className="lit-page-lead">
								{strings.formatString(
									this.state.showResultsTab
										? strings.info_results_belowpresentsinformation
										: this.state.showTestUserInfo
										? strings.info_user_belowpresentsinformation
										: this.state.showReportTab
										? infoReportBelowPresentsInformation
										: '',
									<>
										<br />
										<br />
										<b>{completeName}</b>
										<br />{' '}
									</>,

									<span>{birthDate}</span>,
									<span>
										{this.returnTestUserGrade(notAvailable)}
									</span>
								)}
								{testResult.completedAt ? (
									strings.formatString(
										infoTestWasCompletedOn,
										<>
											<b>
												{moment(
													testResult.completedAt
												).format('DD.MM.YYYY')}
												.
											</b>
											<br />
										</>
									)
								) : (
									<>
										{strings.info_testwasNotcompletedon}{' '}
										<br />
									</>
								)}
								{strings.formatString(
									inforScrAgeAtCommencement,
									<b>{testUser.age.years}</b>,

									<b>{testUser.age.months}</b>
								)}
							</p>
						)}
					</div>

					<div className="lit-tab-wrapper">
						<div className="lit-results-line" />
						{this.state.buttonTabs.map((tab, index) => {
							const id = tab.id;
							const value = tab.value;
							const buttonClass = tab.buttonClass;
							const btnText = tab.text;

							// eslint-disable-next-line react/jsx-key
							return (
								<button
									className={buttonClass}
									id={id}
									key={index}
									value={value}
									disabled={buttonClass.includes('active')}
									onClick={(e) => {
										this.setRelevantTab(
											e.target.value,
											index
										);
									}}
								>
									{btnText}
								</button>
							);
						})}
					</div>

					{this.state.showResultsTab &&
						!this.state.showReportTab &&
						!this.state.showInformationTab && (
							<div className="lit-histogram-Test_Details">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											window.print();
											this.updatePrintLog('Results');
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faPrint}
										/>

										<p className="lit-btns-txt">
											{strings.formatString(
												strings.testresult
											)}
										</p>
									</button>
								</div>
								<div className="lit-results-background">
									<div className="sub-test total print-summary">
										{!testResult.completedAt && (
											<div className="incomplete-notice">
												{strings.incompleteMessage}
											</div>
										)}

										<h3 className="sub-test__name big">
											{strings.formatString(
												strings.summary,
												strings.testresult
											)}
										</h3>
										<div className="summary-chart">
											<HorizontalBarChart
												subtests={testResult.subtests}
												testType={this.state.testType}
											/>
										</div>
										<div className="explanations">
											<div className="explanation">
												<div className="explanation__figure negative" />
												<p className="explanation__text">
													{strings.formatString(
														zscorered,
														<em>{strings.under}</em>
													)}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure neutral" />
												<p className="explanation__text">
													{zscoreblue}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure positive" />
												<p className="explanation__text">
													{strings.formatString(
														zscoregreen,
														<em>{strings.over}</em>
													)}
												</p>
											</div>
										</div>
									</div>

									<div className="print-sub-tests">
										{testResult.subtests.map((subtest) => {
											return (
												<Subtest
													subtest={subtest}
													type={this.state.testType}
													testType={
														this.state.testType
													} //is going to be used for better rendering of gjennomsnitting og stadaravvik skåre
													testResult={
														this.state.testResult
													}
													key={subtest.id}
												>
													<SubtestAnswers
														subtest={subtest}
														viewModes={
															this.state.viewModes
														}
													/>
												</Subtest>
											);
										})}
										<div className="lit-results-assessment">
											<Assessment
												testResult={testResult}
												completeName={completeName}
												firstName={firstName}
												testReportLang={
													this.state.testReportLang
												}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
					{showTestUserInfo && (
						<TestUserInfo
							testResult={testResult}
							testType={this.state.testType}
							testReportLang={this.state.testReportLang}
						/>
					)}
					{this.state.showReportTab &&
						!this.state.showTestUserInfo && (
							<div className="lit-histogram-Test_Report">
								<div className="lit-printBtn">
									<button
										className={classNames('print-hide', {
											'lit-btn2 see-more': true,
											'bg-tertiary': true,
										})}
										onClick={() => {
											this.updatePrintLog('Report');
											this.setState({reportNotes: ''});
											this.toggleAddNotesModal();
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faPrint}
										/>

										<p className="lit-btns-txt">
											{strings.formatString(
												strings.printwithcomment,
												strings.testreport
											)}
										</p>
									</button>
									<button
										className="lit-mytests-header-mytest-btn-primary"
										onClick={() => {
											this.onClickTranslateReport();
										}}
									>
										<FontAwesomeIcon
											size="lg"
											className="lit-icon-btns"
											icon={faLanguage}
										/>

										<p className="lit-btns-txt">
											{testReportLang === 'nn'
												? strings._props.nn
														.translateToNorsk
												: strings.translateToNynorsk}
										</p>
									</button>
									{this.state.showAlert && (
										<AlertBox
											type={
												this.state.success
													? 'success'
													: 'error'
											}
											message={this.state.message}
										/>
									)}
								</div>
								<div className="lit-results-background">
									<div className="sub-test total print-summary">
										{!testResult.completedAt && (
											<div className="incomplete-notice">
												{strings.incompleteMessage}
											</div>
										)}

										<h3 className="sub-test__name big">
											{strings.formatString(
												strings.summary,
												strings.testreport
											)}
										</h3>
										<div className="summary-chart">
											<HorizontalBarChart
												subtests={testResult.subtests}
												testType={this.state.testType}
											/>
										</div>
										<div className="explanations">
											<div className="explanation">
												<div className="explanation__figure negative" />
												<p className="explanation__text">
													{strings.formatString(
														zscorered,
														<em>{strings.under}</em>
													)}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure neutral" />
												<p className="explanation__text">
													{zscoreblue}
												</p>
											</div>
											<div className="explanation">
												<div className="explanation__figure positive" />
												<p className="explanation__text">
													{strings.formatString(
														zscoregreen,
														<em>{strings.over}</em>
													)}
												</p>
											</div>
										</div>

										<div className="print-sub-tests">
											{testResult.subtests.map(
												(subtest) => {
													return (
														<SubtestReport
															subtest={subtest}
															key={subtest.id}
															type={
																this.state
																	.testType
															}
															testResult={
																this.state
																	.testResult
															}
															testReportLang={
																this.state
																	.testReportLang
															}
														/>
													);
												}
											)}
										</div>
										<div className="lit-report-assessment">
											<Assessment
												testResult={testResult}
												completeName={completeName}
												firstName={firstName}
												testReportLang={
													this.state.testReportLang
												}
											/>
										</div>

										{reportNotes && (
											<>
												<div className="sub-test__comment-page-breaker" />
												<div className="sub-test__comment">
													<h4 className="sub-test__heading big">
														<span className="comment">
															Kommentarer:
														</span>
														<p className="report-notes">
															{reportNotes}
														</p>
														<div className="line-one-text">
															Kommentaren er
															skrevet av
															testadministrator:{' '}
															{ownerFullName}
														</div>
														<div className="line-container">
															<div className="line line-1" />
														</div>
													</h4>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						)}
					{this.state.showHandBookTab && <HandBook />}
					{this.state.showAddNotesModal && (
						<AddNotesModal
							showModal={this.state.showAddNotesModal}
							toggleModal={this.toggleAddNotesModal}
							reportNotes={reportNotes}
							setReportNotes={(notes) => {
								this.setState({reportNotes: notes});
							}}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SingleTest)
);
